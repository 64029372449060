import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

const CreditWrap = styled.div`
  margin: 3rem 0 1rem;
  padding: 1rem 2rem;
  background-color: ${props => props.theme.colors.note};
  color: ${props => props.theme.colors.grey.default};
  border-radius: 10px;

  &::before {
    display: block;
    content: "credits";
    text-align: center;
    color: ${props => props.theme.colors.grey.light};
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
`

const Credits = ({ credit }) => (
  <CreditWrap>
    <ul>
      <li>
        <ReactMarkdown source={credit} />
      </li>
    </ul>
  </CreditWrap>
)

export default Credits

