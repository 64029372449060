import React, { Component } from 'react'
import styled from 'styled-components'
import Fade from '@material-ui/core/Fade';

const VideoWrap = styled.div`
  margin: 3rem auto;
  text-align: center;

  h3 {
    color: ${props => props.theme.colors.grey.light};
    font-family: ${props => props.theme.fontFamily.display};
  }
  iframe {
    max-width: 100%;
    border-radius: 17px;
    box-shadow: 0 10px 17px rgba(0,0,0,0.25);
  }
`

const Video = ({ url, title, ...props }) => (
  <Fade in={true} style={{ transitionDelay: '1100ms' }}>
    <VideoWrap className="video">
      <h3>{title}</h3>
      <iframe
        src={url}
        title={title}
        width="601"
        height="338"
        showinfo="0"
        controls="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </VideoWrap>
  </Fade>
)

export default Video
